import { navigate } from 'gatsby';
import { useLayoutEffect } from 'react';

import getBrowserLocale from '../utils/getBrowserLocale';

const TermsOfService = () => {
  // 言語に対応したページにリダイレクト
  useLayoutEffect(() => {
    if (getBrowserLocale() === 'ja') {
      navigate('/ja/terms-of-service', { replace: true });
    } else {
      navigate('/en/terms-of-service', { replace: true });
    }
  }, []);
  return null;
};
export default TermsOfService;
